<template>
  <div class="box">
    <el-breadcrumb>
      <el-breadcrumb-item>反馈管理</el-breadcrumb-item>
      <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <el-form label-width="120px" class="search-form" size="mini">
        <el-row style="margin-top: 20px;">
          <el-col :span="8">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="queryInfo.condition.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="queryInfo.condition.phone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="问题类型" prop="type">
              <el-input v-model="queryInfo.condition.type" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="6" :offset="18" style="display: flex; justify-content: flex-end;">
            <el-button type="primary" @click="search" icon="el-icon-search">查询</el-button>
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 440px)" border :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" width="200" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="100" align="center">
        </el-table-column>
        <el-table-column label="反馈图片" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.imageUrlList && scope.row.imageUrlList.length > 0">
              <div style="">
                <img :src="item" alt="" v-for="(item, index) in scope.row.imageUrlList">
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="问题描述" align="center">

        </el-table-column>
        <el-table-column prop="createTime" label="反馈时间" width="150" align="center">

        </el-table-column>

        <el-table-column prop=" " label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="reply(scope.row.id)">回复</el-button>
            <el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red"
              title="确定的删除该条记录？" @onConfirm="del(scope.row.id)">
              <el-button type="text" slot="reference" style="color: red;">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="showDialog">
      <div class="dialogBox">
        <div class="title">
          <span style="font-weight: bold;">意见反馈详情</span>
          <span class="same">反馈时间：{{ feedbackObj.createTime }}</span>
        </div>
        <div class="feedBackTop">
          <div style="font-weight: bold;">问题类型：{{ feedbackObj.type }}</div>
          <el-row style="margin: 20px 0;">
            <el-col :span="12">用户ID：{{ feedbackObj.userCode }}</el-col>
            <el-col :span="12">用户昵称：{{ feedbackObj.nickName }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="12">用户手机号：{{ feedbackObj.phone }}</el-col>
            <el-col :span="12">关联订单：{{ feedbackObj.orderId }}</el-col>
          </el-row>
        </div>
        <div class="feedBackCenter">
          <div style="font-weight: bold;">问题描述</div>
          <div class="sameBox">
            <span class="same sameWidth">问题描述：</span>
            <textarea class="sameTextA" v-model="feedbackObj.description" style="resize:none;" rows="4"></textarea>
          </div>
          <div class="sameBox" v-if="feedbackObj.imageUrlList && feedbackObj.imageUrlList.length > 0">
            <span class="same sameWidth">反馈图片：</span>
            <div v-for="(item, index) in feedbackObj.imageUrlList">
              <el-image style="width: 100px; height: 100px;margin: 0 5px;" :src="item"
                :preview-src-list="feedbackObj.imageUrlList">
              </el-image>
            </div>
          </div>
          <div class="sameBox">
            <span class="same sameWidth">平台回复：</span>
            <textarea class="sameTextA" v-model="feedbackObj.reply" style="resize:none;" rows="4"></textarea>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmBtn">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'second',
      drawer: false,
      tableData: [{
        id: 122,
        businessName: '十八点',
        allowPayoutsNum: 1888,
        payoutsTotalNum: 8821,
        linkPhone: 15088762311,
        scope: '和您当前',
        industryType: 1,
        businessStatus: 1,
        licenceImg: './img',
        idCardImgFront: './img',
        idCardImgReverse: './img',
        qualifications: './img',
        applicantTime: '1998-09-80',
        auditStatus: '2'
      }],
      queryInfo: {
        condition: {
          id: '',
          name: '',
          phone: '',
          type: '',
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10
      },
      total: 0,
      showDialog: false,
      businessdetail: {},
      id: '',
      feedbackObj: {},
      description: '',
      feedback: '',
      time: '2024-07-04 12:33'
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    change(e) {
      console.log(e);
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/opinion/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    reply(id) {
      let _this = this
      this.id = id
      this.showDialog = true
      this.$http.post('/opinion/queryOne', { id: id }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.feedbackObj = response.data.data
          console.log("_+_+_+_+", _this.feedbackObj);
        }
      })
    },
    del(id) {
      console.log("+++++++++++++++", id);

      this.id = id
      var _this = this;

      this.$http.post("/opinion/delete", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.$message({
            message: '删除成功',
            type: 'success'
          });
          _this.getList()
          // that.businessdetail = response.data.data
        }
      });

    },
    confirmBtn() {
      let _this = this
      this.$http.post('/opinion/update', this.feedbackObj).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          _this.$message({
            message: '已回复',
            type: 'success'
          });
          _this.showDialog = false
        }
      })
    },
    search() {
      this.queryInfo.currPage = 1;
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {
        id: '',
        name: '',
        phone: '',
        type: '',
      }
      this.queryInfo.currPage = 1;
      this.getList()
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 15px;

    .search-top {
      padding: 5px;
      background-color: rgba(30, 152, 215, 0.14);
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      div {
        width: 150px;
        text-align: center;
      }
    }

    .search-form {
      padding-right: 50px;
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 15px;
    padding: 15px
  }
}

/deep/ .el-switch__label * {
  font-size: 12px;
}

.dialogBox {
  position: relative;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  position: absolute;
  top: -40px;
  left: 0;
}

.title>span:first-of-type {
  margin-right: 20px;
}

.same {
  color: rgb(138, 138, 138);
  // font-size: 10px;
}

.feedBackTop {
  border-top: 1px solid rgb(138, 138, 138);
  border-bottom: 1px solid rgb(138, 138, 138);
  padding: 20px;
}

.feedBackCenter {
  padding: 20px;
}

.sameBox {
  margin: 20px 0;
  display: flex;
}

.sameWidth {
  width: 70px;
}

.sameTextA {
  flex: 1;
}
</style>
